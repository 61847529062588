<template>
  <v-container fluid>
    <v-expansion-panels :value="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header
          >{{ $t("searchPanel") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-select
                :items="voucherTypeFilter"
                item-text="text"
                item-value="value"
                hide-details
                outlined
                dense
                v-model="filterItem.voucherType"
                :label="$t('vouchers.voucherType')"
                @change="refreshTable"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                :items="voucherLockFilter"
                item-text="text"
                item-value="value"
                hide-details
                outlined
                dense
                v-model="filterItem.voucherLock"
                :label="$t('vouchers.lockVoucher')"
                @change="refreshTable"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                :items="voucherAcceptFilter"
                item-text="text"
                item-value="value"
                hide-details
                outlined
                dense
                v-model="filterItem.voucherAccept"
                :label="$t('vouchers.acceptVoucher')"
                @change="refreshTable"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-dialog
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterItem.fromDate"
                    hide-details
                    outlined
                    dense
                    :label="$t('fromDate')"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterItem.fromDate"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-dialog>
            </v-col>
            <v-col>
              <v-dialog
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hide-details
                    outlined
                    dense
                    v-model="filterItem.toDate"
                    :label="$t('toDate')"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterItem.toDate"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" @click="refreshTable()">
                <v-icon> mdi-refresh </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card class="v-card-profile pb-2 mt-3" elevation="5">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="vouchers"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('search')"
                single-line
                hide-details
                class="d-block"
              ></v-text-field>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-slot:activator="{ on, attrs }"
                  v-if="isInRole('58')"
                >
                  <v-btn
                    color="error darken-1"
                    darkd
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="addItem(2)"
                  >
                    {{ $t("vouchers.paymentType") }}

                    <v-icon class="ma-1"> mdi-credit-card </v-icon>
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    dark
                    class="mb-2 mx-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="addItem(1)"
                  >
                    {{ $t("vouchers.receiptType") }}

                    <v-icon class="ma-1"> mdi-receipt </v-icon>
                  </v-btn>
                </template>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card>
                    <v-toolbar color="primary darken-1" dark>
                      <v-card-title>
                        <span class="">{{
                          (editedIndex === -1 ? $t("add") : $t("edit")) +
                            " " +
                            (voucherType && voucherType == 1
                              ? $t("vouchers.receiptType")
                              : $t("vouchers.paymentType"))
                        }}</span>
                      </v-card-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="selectedAccountTree"
                              :items="accountTree"
                              :rules="rules"
                              :label="$t('vouchers.accountTreeGuid')"
                              item-text="accountDisplayName"
                              item-value="guid"
                              return-object
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              :items="accountTree"
                              v-model="selectedAccountContra"
                              :rules="rules"
                              :label="$t('vouchers.accountContraGuid')"
                              item-text="accountDisplayName"
                              item-value="guid"
                              return-object
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="voucherAmount"
                              :rules="rules"
                              :label="$t('vouchers.voucherAmount')"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="sourceText"
                              :rules="rules"
                              :label="$t('vouchers.sourceText')"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="note"
                              :label="$t('notes')"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        class="white--text"
                        @click="save"
                        :disabled="!valid"
                        :min-width="100"
                      >
                        <v-icon>mdi-content-save-outline</v-icon>
                        {{ $t("save") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red" text @click="close">
                        {{ $t("cancel") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
              <confirm-dialog
                :openDialog="dialogDelete"
                :onClicked="deleteItemConfirm"
                :onClose="closeDelete"
              ></confirm-dialog>

              <confirm-dialog
                :openDialog="dialogAccept"
                :onClicked="acceptVoucherConfirm"
                :onClose="closeAccept"
                :dialogTitle="
                  editedItem.isAccepted
                    ? $t('vouchers.unAcceptVoucher')
                    : $t('vouchers.acceptVoucher')
                "
              ></confirm-dialog>

              <confirm-dialog
                :openDialog="dialogLock"
                :onClicked="lockVoucherConfirm"
                :onClose="closeLock"
                :dialogTitle="
                  editedItem.isLocked
                    ? $t('vouchers.unlockVoucher')
                    : $t('vouchers.lockVoucher')
                "
              ></confirm-dialog>
            </v-toolbar>
          </template>

          <template v-slot:[`item.accountTreeGuid`]="{ item }">
            {{ getAccountTreeName(item.accountTreeGuid) }}
          </template>
          <template v-slot:[`item.accountContraGuid`]="{ item }">
            {{ getAccountTreeName(item.accountContraGuid) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="text-end" style="width: 180px">
              <v-row no-gutters>
                <v-col>
                  <v-tooltip
                    top
                    v-if="
                      item.sourceType == 0 && !item.isLocked && isInRole('59')
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        rounded
                        class=""
                        v-bind="attrs"
                        v-on="on"
                        @click="editItem(item)"
                        ><v-icon class="">mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("edit") }}
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col>
                  <v-tooltip top v-if="!item.isLocked && isInRole('60')">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        rounded
                        color="red darken-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteItem(item)"
                        ><v-icon class="">mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("delete") }}
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col>
                  <v-tooltip top v-if="isInRole('61')">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        rounded
                        @click="acceptVoucher(item)"
                        color="green darken-2"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon
                          class=""
                          v-text="
                            item.isAccepted
                              ? 'mdi-cash-lock'
                              : 'mdi-cash-lock-open'
                          "
                        >
                        </v-icon>
                      </v-btn>
                    </template>
                    <span
                      v-text="
                        item.isAccepted
                          ? $t('vouchers.unAcceptVoucher')
                          : $t('vouchers.acceptVoucher')
                      "
                    >
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col>
                  <v-tooltip top v-if="!item.isAccepted && isInRole('62')">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        rounded
                        @click="lockVoucher(item)"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon
                          class=""
                          v-text="
                            item.isLocked
                              ? 'mdi-lock-outline'
                              : 'mdi-lock-open-variant-outline'
                          "
                        >
                        </v-icon>
                      </v-btn>
                    </template>
                    <span
                      v-text="
                        item.isLocked
                          ? $t('vouchers.unlockVoucher')
                          : $t('vouchers.lockVoucher')
                      "
                    >
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        rounded
                        class=""
                        v-bind="attrs"
                        v-on="on"
                        @click="printItem(item)"
                        ><v-icon class=""> mdi-printer </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("print") }}
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:[`item.voucherType`]="{ item }">
            {{
              item.voucherType == 1
                ? $t("vouchers.receiptType")
                : $t("vouchers.paymentType")
            }}
          </template>
          <template v-slot:[`item.sourceType`]="{ item }">
            {{
              item.sourceType == 0
                ? $t("vouchers.directType")
                : $t("vouchers.billType")
            }}
          </template>
          <template v-slot:[`item.voucherAmount`]="{ item }">
            {{ item.voucherAmount | number("0,0") }}
          </template>
          <template v-slot:[`item.isLocked`]="{ item }">
            <v-checkbox dense v-model="item.isLocked" disabled />
          </template>
          <template v-slot:[`item.isAccepted`]="{ item }">
            <v-checkbox dense v-model="item.isAccepted" disabled />
          </template>
          <template v-slot:[`item.dateReg`]="{ item }">
            {{ item.dateReg | moment("YYYY/MM/DD hh:mm A") }}
          </template>

          <template slot="body.append">
            <tr class="primary--text text--darken-3">
              <td class="d-none d-sm-flex pa-3">{{ $t("total") }}</td>
              <td :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"></td>
              <td :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"></td>
              <td
                :class="
                  $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                "
              >
                <span
                  :class="$vuetify.breakpoint.xsOnly ? '' : 'hidden-sm-and-up'"
                >
                  {{ $t("total") }}
                </span>
                {{ sumVoucher() | number("0,0") }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-divider />
        <v-row class="mt-2">
          <v-col cols="2"></v-col>

          <v-col align-self="end">
            <v-pagination
              class="mb-2 "
              v-model="page"
              :length="pageLength ? pageLength : 0"
            ></v-pagination>
          </v-col>
          <v-col align-self="center" cols="2">
            <v-select
              dense
              class="pa-1"
              solo
              :label="$t('itemPerPage')"
              :items="itemPerPageSelect"
              item-text="text"
              item-value="value"
              v-model="itemsPerPage"
              hide-details=""
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- <data-table-footer
          :itemLength="this.vouchers.length"
          @changePagePaginiation="changePagePaginiation"
        ></data-table-footer> -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
//import DataTableFooter from "../../components/DataTableFooter.vue";

export default {
  components: {
    ConfirmDialog,
    // DataTableFooter
  },

  data() {
    return {
      page: 1,
      itemsPerPage: 5,
      itemPerPageSelect: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 },
        { text: this.$t("all"), value: -1 },
      ],
      dialog: false,
      dialogDelete: false,
      dialogAccept: false,
      dialogLock: false,
      menu1: false,
      menu2: false,
      panel: [0],
      row: null,
      row1: null,
      row2: null,

      search: "",
      currentType: 0,
      valid: true,
      vouchers: [],
      headers: [
        { text: this.$t("vouchers.voucherNumber"), value: "voucherNumber" },
        { text: this.$t("vouchers.accountTreeGuid"), value: "accountTreeGuid" },
        {
          text: this.$t("vouchers.accountContraGuid"),
          value: "accountContraGuid",
        },
        { text: this.$t("vouchers.voucherAmount"), value: "voucherAmount" },
        { text: this.$t("vouchers.voucherType"), value: "voucherType" },
        { text: this.$t("vouchers.sourceType"), value: "sourceType" },
        { text: this.$t("date"), value: "dateReg" },
        { text: this.$t("vouchers.isAccepted"), value: "isAccepted" },
        { text: this.$t("vouchers.isLocked"), value: "isLocked" },
        { text: this.$t("notes"), value: "note" },
        { text: "", value: "actions" },
      ],

      editedIndex: -1,
      editedItem: {
        accountTreeGuid: "",
        accountContraGuid: "",
        voucherAmount: 0,
        voucherType: 0,
        sourceText: "",
        note: "",
      },
      selectedAccountTree: {},
      selectedAccountContra: {},
      voucherAmount: 0,
      voucherType: 0,
      sourceText: "",
      note: "",
      defaultItem: {
        accountTreeGuid: "",
        accountContraGuid: "",
        voucherAmount: 0,
        voucherType: 0,
        sourceText: "",
        note: "",
      },
      filterItem: {
        voucherType: 0,
        voucherLock: 0,
        voucherAccept: 0,
        fromDate: "",
        toDate: "",
      },
      voucherTypeFilter: [
        { text: this.$t("vouchers.both"), value: 0 },
        { text: this.$t("vouchers.receiptType"), value: 1 },
        { text: this.$t("vouchers.paymentType"), value: 2 },
      ],
      voucherLockFilter: [
        { text: this.$t("vouchers.both"), value: 0 },
        { text: this.$t("vouchers.locked"), value: 1 },
        { text: this.$t("vouchers.notLocked"), value: 2 },
      ],
      voucherAcceptFilter: [
        { text: this.$t("vouchers.both"), value: 0 },
        { text: this.$t("vouchers.accepted"), value: 1 },
        { text: this.$t("vouchers.notAccepted"), value: 2 },
      ],

      accountTree: [],

      rules: [
        (value) => !!value || this.$t("ThisFieldIsRequired"),
        //(value) => (value && value.length <= 5) || "Max 5 characters",
      ],
    };
  },
  created() {
    this.refreshTable();
    axios.get("AccountTree").then((res) => {
      this.accountTree = res.data.data;
    });
    this.filterItem.fromDate = this.dateNow;
    this.filterItem.toDate = this.dateNow;
  },

  computed: {
    pageLength() {
      if (this.itemsPerPage == -1) {
        return 1;
      } else {
        var div = this.vouchers.length / this.itemsPerPage;
        return Number.isInteger(div) ? div : (div | 0) + 1;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // pageLength(val) {
    //   if (this.page > val) this.page = val;
    // },
  },

  methods: {
    refreshTable() {
      axios
        .get("Voucher/Get?filter=" + JSON.stringify(this.filterItem))
        .then((response) => {
          this.vouchers = response.data.data;

          console.log(response.data.data);
        });
    },

    sumVoucher() {
      if (this.vouchers)
        return this.vouchers.reduce((a, b) => a + (b["voucherAmount"] || 0), 0);
      return 0;
    },

    changePagePaginiation(obj) {
      console.log(obj);
      this.page = obj.page;
      this.itemsPerPage = obj.itemPerPage;
    },
    getAccountTreeName(guid) {
      var account = this.accountTree.filter((f) => {
        return f.guid == guid;
      })[0];

      if (account) return account.accountDisplayName;
      return "";
    },
    addItem(type) {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.voucherType = type;

      var defult = this.accountTree.filter((f) => {
        return f.isDefault;
      });
      if (type == 1) {
        this.selectedAccountContra = defult[0];
      } else {
        this.selectedAccountTree = defult[0];
      }

      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.vouchers.indexOf(item);

      this.voucherType = item.voucherType;
      this.selectedAccountTree = this.accountTree.filter((f) => {
        return f.guid == item.accountTreeGuid;
      })[0];

      this.selectedAccountContra = this.accountTree.filter((f) => {
        return f.guid == item.accountContraGuid;
      })[0];
      this.voucherAmount = item.voucherAmount;
      this.sourceText = item.sourceText;
      this.note = item.note;

      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.vouchers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.vouchers[this.editedIndex];

      axios
        .delete("Voucher/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable(this.$route.params.type);
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
      this.closeDelete();
    },

    acceptVoucher(item) {
      this.editedIndex = this.vouchers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogAccept = true;
    },

    acceptVoucherConfirm() {
      var acceptVoucher = this.vouchers[this.editedIndex];

      axios
        .get("Voucher/Accept?guid=" + acceptVoucher.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable(this.$route.params.type);
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
      this.closeAccept();
    },

    lockVoucher(item) {
      this.editedIndex = this.vouchers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogLock = true;
    },

    lockVoucherConfirm() {
      var lockVoucher = this.vouchers[this.editedIndex];

      axios
        .get("Voucher/Lock?guid=" + lockVoucher.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable(this.$route.params.type);
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
      this.closeLock();
    },

    printItem(item) {
      axios({
        url: "Voucher/Print?guid=" + item.guid,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.voucherType = 0;
        this.selectedAccountTree = "";
        this.selectedAccountContra = "";
        this.voucherAmount = 0;
        this.sourceText = 0;
        this.note = "";
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeAccept() {
      this.dialogAccept = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeLock() {
      this.dialogLock = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.vouchers[this.editedIndex];
          updatedItem.accountTreeGuid = this.selectedAccountTree.guid;
          updatedItem.accountContraGuid = this.selectedAccountContra.guid;
          updatedItem.voucherAmount = this.voucherAmount;
          updatedItem.sourceText = this.sourceText;
          updatedItem.note = this.note;

          axios
            .post("Voucher/Update", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable(this.$route.params.type);
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
          Object.assign(this.vouchers[this.editedIndex], this.editedItem);
        } else {
          var data = {
            accountTreeGuid: this.selectedAccountTree.guid,
            accountContraGuid: this.selectedAccountContra.guid,
            voucherAmount: this.voucherAmount,
            voucherType: this.voucherType,
            sourceText: this.sourceText,
            note: this.note,
          };
          console.log(data);
          axios
            .post("Voucher/Add", data)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable(this.$route.params.type);
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
